export const MODELS = [
  {
    id: 1,
    name: "Lexus",
    path: "lexus",
    description:
      "WE ARE A LOCALLY OWNED AUTOMOTIVE WRECKING YARD LOCATED IN RANCHO CORDOVA, CA. WE SPECIALIZE IN MAZDA, HONDA, ACURA, NISSAN, INFINITI PLEASE CALL US FOR ANY AUTOMOTIVE PART NEED YOU MAY HAVE",
    img: "lexus",
    imgs: ["bmw", "ford", "lexus", "infiniti"],
  },
  {
    id: 2,
    name: "Bmw",
    path: "bmw",
    description:
      "WE ARE A LOCALLY OWNED AUTOMOTIVE WRECKING YARD LOCATED IN RANCHO CORDOVA, CA. WE SPECIALIZE IN MAZDA, HONDA, ACURA, NISSAN, INFINITI PLEASE CALL US FOR ANY AUTOMOTIVE PART NEED YOU MAY HAVE",
    img: "bmw",
    imgs: ["bmw", "ford", "lexus", "infiniti"],
  },
  {
    id: 3,
    name: "Ford",
    path: "ford",
    description:
      "WE ARE A LOCALLY OWNED AUTOMOTIVE WRECKING YARD LOCATED IN RANCHO CORDOVA, CA. WE SPECIALIZE IN MAZDA, HONDA, ACURA, NISSAN, INFINITI PLEASE CALL US FOR ANY AUTOMOTIVE PART NEED YOU MAY HAVE",
    img: "ford",
    imgs: ["bmw", "ford", "lexus", "infiniti"],
  },
  {
    id: 4,
    name: "Hyundai",
    path: "hyundai",
    description:
      "WE ARE A LOCALLY OWNED AUTOMOTIVE WRECKING YARD LOCATED IN RANCHO CORDOVA, CA. WE SPECIALIZE IN MAZDA, HONDA, ACURA, NISSAN, INFINITI PLEASE CALL US FOR ANY AUTOMOTIVE PART NEED YOU MAY HAVE",
    img: "hyundai",
    imgs: ["bmw", "ford", "lexus", "infiniti"],
  },
  {
    id: 5,
    name: "Infiniti",
    path: "infiniti",
    description:
      "WE ARE A LOCALLY OWNED AUTOMOTIVE WRECKING YARD LOCATED IN RANCHO CORDOVA, CA. WE SPECIALIZE IN MAZDA, HONDA, ACURA, NISSAN, INFINITI PLEASE CALL US FOR ANY AUTOMOTIVE PART NEED YOU MAY HAVE",
    img: "infiniti",
    imgs: ["bmw", "ford", "lexus", "infiniti"],
  },
  {
    id: 6,
    name: "Mazda",
    path: "mazda",
    description:
      "WE ARE A LOCALLY OWNED AUTOMOTIVE WRECKING YARD LOCATED IN RANCHO CORDOVA, CA. WE SPECIALIZE IN MAZDA, HONDA, ACURA, NISSAN, INFINITI PLEASE CALL US FOR ANY AUTOMOTIVE PART NEED YOU MAY HAVE",
    img: "mazda",
    imgs: ["bmw", "ford", "lexus", "infiniti"],
  },
  {
    id: 7,
    name: "Nissan",
    path: "nissan",
    description:
      "WE ARE A LOCALLY OWNED AUTOMOTIVE WRECKING YARD LOCATED IN RANCHO CORDOVA, CA. WE SPECIALIZE IN MAZDA, HONDA, ACURA, NISSAN, INFINITI PLEASE CALL US FOR ANY AUTOMOTIVE PART NEED YOU MAY HAVE",
    img: "nissan",
    imgs: ["bmw", "ford", "lexus", "infiniti"],
  },
  {
    id: 8,
    path: "audi",
    description:
      "WE ARE A LOCALLY OWNED AUTOMOTIVE WRECKING YARD LOCATED IN RANCHO CORDOVA, CA. WE SPECIALIZE IN MAZDA, HONDA, ACURA, NISSAN, INFINITI PLEASE CALL US FOR ANY AUTOMOTIVE PART NEED YOU MAY HAVE",
    img: "audi",
  },
  {
    id: 9,
    path: "buick",
    description:
      "WE ARE A LOCALLY OWNED AUTOMOTIVE WRECKING YARD LOCATED IN RANCHO CORDOVA, CA. WE SPECIALIZE IN MAZDA, HONDA, ACURA, NISSAN, INFINITI PLEASE CALL US FOR ANY AUTOMOTIVE PART NEED YOU MAY HAVE",
    img: "buick",
  },
  {
    id: 10,
    path: "chrysler",
    description:
      "WE ARE A LOCALLY OWNED AUTOMOTIVE WRECKING YARD LOCATED IN RANCHO CORDOVA, CA. WE SPECIALIZE IN MAZDA, HONDA, ACURA, NISSAN, INFINITI PLEASE CALL US FOR ANY AUTOMOTIVE PART NEED YOU MAY HAVE",
    img: "chrysler",
  },
  {
    id: 11,
    path: "honda",
    description:
      "WE ARE A LOCALLY OWNED AUTOMOTIVE WRECKING YARD LOCATED IN RANCHO CORDOVA, CA. WE SPECIALIZE IN MAZDA, HONDA, ACURA, NISSAN, INFINITI PLEASE CALL US FOR ANY AUTOMOTIVE PART NEED YOU MAY HAVE",
    img: "honda",
  },
  {
    id: 12,
    path: "porsche",
    description:
      "WE ARE A LOCALLY OWNED AUTOMOTIVE WRECKING YARD LOCATED IN RANCHO CORDOVA, CA. WE SPECIALIZE IN MAZDA, HONDA, ACURA, NISSAN, INFINITI PLEASE CALL US FOR ANY AUTOMOTIVE PART NEED YOU MAY HAVE",
    img: "porsche",
  },
  {
    id: 13,
    path: "subaru",
    description:
      "WE ARE A LOCALLY OWNED AUTOMOTIVE WRECKING YARD LOCATED IN RANCHO CORDOVA, CA. WE SPECIALIZE IN MAZDA, HONDA, ACURA, NISSAN, INFINITI PLEASE CALL US FOR ANY AUTOMOTIVE PART NEED YOU MAY HAVE",
    img: "subaru",
  },
  {
    id: 14,
    path: "tesla",
    description:
      "WE ARE A LOCALLY OWNED AUTOMOTIVE WRECKING YARD LOCATED IN RANCHO CORDOVA, CA. WE SPECIALIZE IN MAZDA, HONDA, ACURA, NISSAN, INFINITI PLEASE CALL US FOR ANY AUTOMOTIVE PART NEED YOU MAY HAVE",
    img: "tesla",
  },
  {
    id: 15,
    path: "toyota",
    description:
      "WE ARE A LOCALLY OWNED AUTOMOTIVE WRECKING YARD LOCATED IN RANCHO CORDOVA, CA. WE SPECIALIZE IN MAZDA, HONDA, ACURA, NISSAN, INFINITI PLEASE CALL US FOR ANY AUTOMOTIVE PART NEED YOU MAY HAVE",
    img: "toyota",
  },
  {
    id: 16,
    path: "dodge",
    description:
      "WE ARE A LOCALLY OWNED AUTOMOTIVE WRECKING YARD LOCATED IN RANCHO CORDOVA, CA. WE SPECIALIZE IN MAZDA, HONDA, ACURA, NISSAN, INFINITI PLEASE CALL US FOR ANY AUTOMOTIVE PART NEED YOU MAY HAVE",
    img: "dodge",
  },
  {
    id: 18,
    path: "mini",
    description:
      "WE ARE A LOCALLY OWNED AUTOMOTIVE WRECKING YARD LOCATED IN RANCHO CORDOVA, CA. WE SPECIALIZE IN MAZDA, HONDA, ACURA, NISSAN, INFINITI PLEASE CALL US FOR ANY AUTOMOTIVE PART NEED YOU MAY HAVE",
    img: "mini",
  },
  {
    id: 19,
    path: "volkswagen",
    description:
      "WE ARE A LOCALLY OWNED AUTOMOTIVE WRECKING YARD LOCATED IN RANCHO CORDOVA, CA. WE SPECIALIZE IN MAZDA, HONDA, ACURA, NISSAN, INFINITI PLEASE CALL US FOR ANY AUTOMOTIVE PART NEED YOU MAY HAVE",
    img: "volkswagen",
  },
  {
    id: 20,
    path: "kia",
    description:
      "WE ARE A LOCALLY OWNED AUTOMOTIVE WRECKING YARD LOCATED IN RANCHO CORDOVA, CA. WE SPECIALIZE IN MAZDA, HONDA, ACURA, NISSAN, INFINITI PLEASE CALL US FOR ANY AUTOMOTIVE PART NEED YOU MAY HAVE",
    img: "kia",
  },
  {
    id: 21,
    path: "volvo",
    description:
      "WE ARE A LOCALLY OWNED AUTOMOTIVE WRECKING YARD LOCATED IN RANCHO CORDOVA, CA. WE SPECIALIZE IN MAZDA, HONDA, ACURA, NISSAN, INFINITI PLEASE CALL US FOR ANY AUTOMOTIVE PART NEED YOU MAY HAVE",
    img: "volvo",
  },
  {
    id: 22,
    path: "mercedes",
    description:
      "WE ARE A LOCALLY OWNED AUTOMOTIVE WRECKING YARD LOCATED IN RANCHO CORDOVA, CA. WE SPECIALIZE IN MAZDA, HONDA, ACURA, NISSAN, INFINITI PLEASE CALL US FOR ANY AUTOMOTIVE PART NEED YOU MAY HAVE",
    img: "mercedes",
  },
  {
    id: 23,
    path: "cadillac",
    description:
      "WE ARE A LOCALLY OWNED AUTOMOTIVE WRECKING YARD LOCATED IN RANCHO CORDOVA, CA. WE SPECIALIZE IN MAZDA, HONDA, ACURA, NISSAN, INFINITI PLEASE CALL US FOR ANY AUTOMOTIVE PART NEED YOU MAY HAVE",
    img: "cadillac",
  },
  {
    id: 24,
    path: "acura",
    description:
      "WE ARE A LOCALLY OWNED AUTOMOTIVE WRECKING YARD LOCATED IN RANCHO CORDOVA, CA. WE SPECIALIZE IN MAZDA, HONDA, ACURA, NISSAN, INFINITI PLEASE CALL US FOR ANY AUTOMOTIVE PART NEED YOU MAY HAVE",
    img: "acura",
  },
  {
    id: 25,
    path: "chevrolet",
    description:
      "WE ARE A LOCALLY OWNED AUTOMOTIVE WRECKING YARD LOCATED IN RANCHO CORDOVA, CA. WE SPECIALIZE IN MAZDA, HONDA, ACURA, NISSAN, INFINITI PLEASE CALL US FOR ANY AUTOMOTIVE PART NEED YOU MAY HAVE",
    img: "chevrolet",
  },
  {
    id: 26,
    path: "GMC",
    description:
      "WE ARE A LOCALLY OWNED AUTOMOTIVE WRECKING YARD LOCATED IN RANCHO CORDOVA, CA. WE SPECIALIZE IN MAZDA, HONDA, ACURA, NISSAN, INFINITI PLEASE CALL US FOR ANY AUTOMOTIVE PART NEED YOU MAY HAVE",
    img: "gmc",
  },
  {
    id: 27,
    path: "jaguar",
    description:
      "WE ARE A LOCALLY OWNED AUTOMOTIVE WRECKING YARD LOCATED IN RANCHO CORDOVA, CA. WE SPECIALIZE IN MAZDA, HONDA, ACURA, NISSAN, INFINITI PLEASE CALL US FOR ANY AUTOMOTIVE PART NEED YOU MAY HAVE",
    img: "jaguar",
  },
  {
    id: 28,
    path: "lincoln",
    description:
      "WE ARE A LOCALLY OWNED AUTOMOTIVE WRECKING YARD LOCATED IN RANCHO CORDOVA, CA. WE SPECIALIZE IN MAZDA, HONDA, ACURA, NISSAN, INFINITI PLEASE CALL US FOR ANY AUTOMOTIVE PART NEED YOU MAY HAVE",
    img: "lincoln",
  },
  {
    id: 29,
    path: "jeep",
    description:
      "WE ARE A LOCALLY OWNED AUTOMOTIVE WRECKING YARD LOCATED IN RANCHO CORDOVA, CA. WE SPECIALIZE IN MAZDA, HONDA, ACURA, NISSAN, INFINITI PLEASE CALL US FOR ANY AUTOMOTIVE PART NEED YOU MAY HAVE",
    img: "jeep",
  },
  {
    id: 30,
    path: "mitsubishi",
    description:
      "WE ARE A LOCALLY OWNED AUTOMOTIVE WRECKING YARD LOCATED IN RANCHO CORDOVA, CA. WE SPECIALIZE IN MAZDA, HONDA, ACURA, NISSAN, INFINITI PLEASE CALL US FOR ANY AUTOMOTIVE PART NEED YOU MAY HAVE",
    img: "mitsubishi",
  },
  {
    id: 31,
    path: "Land-Rover",
    description:
      "WE ARE A LOCALLY OWNED AUTOMOTIVE WRECKING YARD LOCATED IN RANCHO CORDOVA, CA. WE SPECIALIZE IN MAZDA, HONDA, ACURA, NISSAN, INFINITI PLEASE CALL US FOR ANY AUTOMOTIVE PART NEED YOU MAY HAVE",
    img: "land-rover",
  },
  {
    id: 32,
    path: "Alfa-Romeo",
    description:
      "WE ARE A LOCALLY OWNED AUTOMOTIVE WRECKING YARD LOCATED IN RANCHO CORDOVA, CA. WE SPECIALIZE IN MAZDA, HONDA, ACURA, NISSAN, INFINITI PLEASE CALL US FOR ANY AUTOMOTIVE PART NEED YOU MAY HAVE",
    img: "alfa-romeo",
  },
];
